body,
html {
  overflow-x: hidden;
}

// Menu Admin - Layout
@media (min-width: 569px) {
  #menuAdmin li {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #menuAdmin li a {
    padding: 0.75rem 0.55rem;
  }
  .menuAdminContainer li:not([class*="menuAdmin"]) {
    border-left: 1px solid rgba($white, 0.55);
    min-width: 50px;
    &:hover {
      background-color: rgba($black, 0.05);
    }
  }
  .menuAdminContainer
    li[class*="menuAdmin"]:not(#menu0f0f2925-04aa-43e2-b9c5-58ec5b183b51) {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: -1px;
      height: 14px;
      border-left: 2px dotted #fff;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 1024px) {
  #menuAdmin {
    padding-right: 0;
  }
}

// Menu Admin - Search setup
nav.menuMain form.header-search:first-of-type {
  display: none;
}
.headerContent form.header-search {
  display: none;
}
.menuAdminContainer form.header-search {
  input {
    width: auto !important;
    max-width: 140px;
  }
  button {
    border-left: 1px solid rgba($white, 0.55);
  }
}

// Menu Admin - CTA Donate
#menuAdmin a.cta-button.donate {
  border-radius: 0;
  padding: 0.75rem 2rem;
  margin-right: 0;
  @media (max-width: 1024px) {
    padding: 0.75rem 1;
  }
  background-color: $_mid-purple;
  &:hover {
    background-color: darken($_mid-purple, 10%);
  }
}

// Hide Basket from header
.pageHeader .menuAdminBasket {
  display: none !important;
}

// MenuAdmin in nav - mobile
@media (max-width: 568px) {
  .menuMain .menuAdminAltItems {
    display: list-item;
  }
}

.menuMain {
  border-radius: 0;
}

// Header - Desktop Layout
.headerContent {
  margin: 0 auto;
}
.headerContent
  .mainCallToAction
  *:not(.menuMainAlt):not(.mainCallToActionButtons) {
  display: none;
}

@media (max-width: 568px) {
  .headerContent .mainCallToAction a.cta-button.donate {
    display: inline-block;
  }
  .pageHeader {
    box-shadow: none;
  }
}

// Burger menu
@media (min-width: 569px) {
  a.menuMainAlt {
    color: $_dark-purple;
    background-color: transparent;
    &:hover {
      color: $_orange;
      background-color: transparent;
    }
  }
}
// Header Sticky - Destktop
@media (min-width: 1025px) {
  .pageHeader.sticky .menuMain {
    margin-top: -0.5rem;
  }
}

// Carousel - psuedo element
@media (min-width: 769px) {
  .carouselSlideHeading a {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url($assets-path+"carousel-spark.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 60px;
      height: 60px;
      left: -35px;
      top: -35px;
      z-index: 1;
    }
  }
}

// Carousel Controls - Arrows
.carouselControls a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  &:before {
    content: "";
    background-image: url($assets-path+"arrow-left.png");
    background-size: contain;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
a.carouselControlPrev:before {
  background-image: url($assets-path+"arrow-left.png");
}
a.carouselControlNext:before {
  background-image: url($assets-path+"arrow-right.png");
}

// Card - psuedo element
.homeFeature,
.feedItem,
.listedPost,
blockquote {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 15px;
    right: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    transition: 300ms all ease;
  }
  &:hover:after {
    right: 10px;
    transition: 300ms all ease;
  }
  .homeFeatureDetailsWrapper,
  .feedItemDetailsWrapper,
  .listedPostText {
    position: relative;
    // overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      background-image: url($assets-path+"card-mandala.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      opacity: 0.55;
    }
  }
}
.homeBox1:after {
  background-image: url($assets-path+"arrow-orange.png");
}
.homeBox2:after {
  background-image: url($assets-path+"arrow-yellow.png");
}
.homeBox3:after {
  background-image: url($assets-path+"arrow-white.png");
}
.homeBox4:after {
  background-image: url($assets-path+"arrow-mid-purple.png");
}
.homeFeedBox1 .feedItem:after {
  background-image: url($assets-path+"arrow-mid-purple.png");
}
.homeFeedBox2 .feedItem:after {
  background-image: url($assets-path+"arrow-yellow.png");
}
.homeFeedBox3 .feedItem:after {
  background-image: url($assets-path+"arrow-yellow.png");
}
.listedPost:after {
  background-image: url($assets-path+"arrow-yellow.png");
}
blockquote:after {
  background-image: url($assets-path+"blockquote-icon.png");
  transform: translate(55.5%, 55.5%);
  width: 85px;
  height: 85px;
}
blockquote:hover:after {
  right: 15px;
}

// Impact Stats - Layout
.homefeaturecategory-homeboximpactstats tbody {
  align-items: flex-start;
}
.homefeaturecategory-homeboximpactstats:before {
  background-position: center right;
}

// Home Feed - Layout
@media (min-width: 1025px) {
  .homeFeed:not(.subsiteFeed) {
    flex: auto !important;
    &.homeFeedBox1,
    &.homeFeedBox2 {
      width: 48%;
      .feedItem {
        width: 45% !important;
        flex: auto;
        flex-grow: auto;
        min-width: auto;
      }
    }
  }
}

.homeFeedBox3 {
  position: relative;
  background-color: #f0f0f0;
  z-index: 1;
  margin-top: $spacer * 2.5;
  padding: $spacer * 1.5 0;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 200%;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: #f0f0f0;
    z-index: -1;
  }
}

// HomeFeed Intro Button
.homeFeed {
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 0;
  }

  h3.feedsTitle {
    display: inline-block;
  }
  > .button {
    display: inline-block;
    margin: 0 15px 0 0;
    float: right;
  }
  @media (max-width: 1183px) {
    h3.feedsTitle,
    > .button {
      display: block;
      float: none;
    }
    > .button {
      margin: 15px;
    }
  }
  &.homeFeedBox1 > .button {
    color: $_mid-purple;
    box-shadow: inset 0px 0px 0px 3px $_mid-purple;
    background-color: transparent;
    &:hover {
      color: #fff;
      background-color: $_mid-purple;
    }
  }
  &.homeFeedBox2 > .button {
    color: $_yellow;
    box-shadow: inset 0px 0px 0px 3px $_yellow;
    background-color: transparent;
    &:hover {
      color: #fff;
      background-color: $_yellow;
    }
  }
  &.homeFeedBox3 > .button {
    color: $_orange;
    box-shadow: inset 0px 0px 0px 3px $_orange;
    background-color: transparent;
    &:hover {
      color: #fff;
      background-color: $_orange;
    }
  }
}

// Homepage - Quick giving
.homefeaturecategory-homeboxquickgiving {
  padding: 0 $site-bleed;
}

.homepage-quickgiving--container {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: $_mid-purple;
  border-radius: $border-radius;
}

// Footer
.footerBox.Subsitefooter,
.footerBox.SubsiteNewsletterSign-up {
  display: none;
}

// Newsletter padding
.footerBox[class*="ewsletter"] .featureForm {
  padding: 0 $site-bleed;
}
// Footer Logos
.footerBox.Footerlogos {
  position: relative;
  max-width: $container-max-width;
  margin: $spacer auto;
  padding: 0.5rem $site-bleed;
  background-color: $body-bg;
  z-index: 1;
  .logoContainer {
    margin: $spacer 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      margin: 20px;
      max-width: 180px;
      max-height: 130px;
      object-fit: contain;
      object-position: center;
      @media (max-width: 900px) {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 200%;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: -1;
  }
}
// menu admin seperator

// Footer List horizontal
@media (min-width: 769px) {
  .footerBox.Footerabout ul:last-of-type {
    display: flex;
    margin: $spacer * 2 0;
    li {
      padding: 0 7.5px;
      border-right: 1px solid #eee;
      &:last-of-type {
        border: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

// Custom UL
body:not(.subsite) {
  .contentBlockWrapper .postContent,
  .contentBlockWrapper .postContent .contentWrapper,
  .listingIntro {
    ul:not(.shareList) li {
      list-style: none;
      &:before {
        content: "";
        display: inline-block;
        height: 12.5px;
        width: 12.5px;
        margin-right: 5px;
        background-image: url($assets-path+"arrow-dark-purple.png");
        background-size: contain;
      }
    }
  }
}

// Inner page quick giving
.postContent .quickGivingPanel {
  background-color: $_mid-purple;
  .formQuestion.donationAmount {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    &.donationSelected {
      background-color: $_orange;
      color: $white;
      border-color: $_orange;
    }
  }
  .button {
    display: inline-flex;
    height: 54px;
  }
}

// FAQs
.accordianHeaderText h2 a {
  color: $_yellow;
}
.accordianHeaderText .postSummary {
  display: none;
}
.accordianHeader:hover {
  background-color: $_mid-purple;
}
.accordianHeader.faqQuestion {
  margin: 2rem auto 0;
}
.accordianBody:not(.hidden) {
  background-color: #f0f0f0;
  padding-top: $spacer;
}

.postContent {
  p, a:not([class*="Link"]):not(.buttonIcon), ul, ol {
    font-size: 1.15rem;
  }
  .shareList a {
    font-size: 0 !important;
  }
}

// Subsite bespoke

body.subsite.our-shops {
  font-family: Arial, sans-serif!important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a.cta-button-orange,
  .menuMain .topLevel li.level1 > a,
  blockquote {
    font-family: Arial, sans-serif!important;
  }
  .postContent {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $shop-orange;
      font-family: Arial, sans-serif!important;
    }
  }
  

  .menuAdminContainer,
  .headerTextSubsite {
    display: none;
  }
  .pageHeader {
    box-shadow: none;
    background-color: $shop-green;
    &.sticky {
      .headerContent {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .menuMain {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }
    }
  }

  nav.menuMain ul.topLevel > li:first-of-type a:before {
    content: "\f015";
    font-family: "FontAwesome";
    font-size: 1.45rem;
    margin-right: 5px;
    height: 20px;
    display: inline-flex;
    align-items: center;
  }
  @media (min-width: 1025px) {
    nav.menuMain ul.topLevel > li:first-of-type a {
      font-size: 0;
    }
  }

  a.mainLogo {
    background-image: url($assets-path+"logo-shop.svg");
    height: 125px;
    width: 125px;
  }

  .menuMain a {
    color: $white;
  }
  .menuMain a:hover {
    color: $shop-orange;
  }
  @media (min-width: 1025px) {
    .menuMain .topLevel li.level1 > a {
      font-size: 0.9rem;
      padding: 10px 10px;
    }
  }
  .menuMainAlt {
    color: $white;
    background-color: $shop-green;
  }
  @media (max-width: 1024px) {
    .menuMain {
      background-color: $shop-green;
    }
  }

  h1.title {
    color: $shop-orange;
  }

  .homeFeed {
    width: 100%;
  }
  .listedPost:after,
  .feedItem:after {
    //background-image: url($assets-path+"arrow-orange.png") !important;
    display: none;
  }
  .listedPostText,
  .feedItemDetailsWrapper {
    background-color: $shop-green !important;
  }
  h3.feedTitle a {
    color: $shop-orange !important;
  }
  .listedPostText:after,
  .feedItemDetailsWrapper:after {
    display: none !important;
  }

  .pageFooterWrapper {
    margin-top: 2rem;
  }
  .footerBox[class*="ewsletter"],
  .pageFooterWrapper {
    background-color: $shop-green;
  }

  .post_collapseTitle,
  .post_collapseContent {
    background-color: $shop-green!important;
  }
}

// Custom style - buttons
a.cta-button-orange,
a.cta-button-blue,
a.cta-button-red {
  display: inline-flex;
  position: relative;
  margin: 5px;
  padding: 0.825em 1.65em;
  box-shadow: none;
  font-family: $headings-font-family;
  color: $white;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 10px 0;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
  transition: all 0.2s ease-in-out;
}

a.cta-button-orange {
  background-color: $_orange;
}

a.cta-button-blue {
  background-color: $_blue;
}

a.cta-button-red {
  background-color: $_coral;
}

.postVideoObjectEmbed {
  text-align: center;
}

// Expandable blocks
.post_collapseReadMore a {
  background-color: $cta-btn-background-colour;
  color: $cta-btn-colour;
  padding: 1.65em 0.825em;
  display: inline-block;
  margin-right: 5px;
}

.post_collapseContent,
.post_collapseTitle {
  padding: $site-bleed;
}

.post_collapseContent {
  color: $white;
  display: none;
  background-color: $_mid-purple;
  p {
    color: $white;
  }
  a {
    color: $_yellow;
  }
  a:hover {
    text-decoration: underline;
  }
}

.post_collapseTitle {
  color: $white;
  background-color: $_mid-purple;
  padding: 14px 30px;
  width: calc(100% - 160px);
  margin-bottom: 2px;

  @media (max-width: 1140px) {
    width: 100%;
    text-align: center;
  }

  a {
    text-decoration: none;
  }
}

.post_collapseReadMore {
  width: 160px;
  height: 52px;
  margin-bottom: 2px;

  @media (max-width: 1140px) {
    width: 100%;
    margin-bottom: 20px;
  }

  > a {
    height: 100%;
    width: 100%;
    padding: 0;
    text-align: center;
    line-height: 52px;

    &:after {
      display: inline-block;
      padding: 0;
      margin-left: 8px;
      text-align: center;
      line-height: 22px;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      font-size: 15px;
      font-weight: 400;
    }

    &:hover {
      text-decoration: none;
    }
    img {
      display: none;
    }

    &:after {
      padding-left: 10px;
      content: "\f107";
      font-family: "FontAwesome";
    }
  }
}

@media (max-width: 1024px) {
  .post_collapseTitle,
  .post_collapseReadMore {
    float: none !important;
    width: 100%;
  }
  .post_collapseReadMore a {
    width: 100%;
    text-align: center;
  }
}

// Hide appeal comments
body:not(.inMemType) .appealInteractionsWrapper {
  display: none;
}

// Social Media Landing page
body.PostCategory_social-media-landing-page {
  // Hide all unnecessary items
  nav.menuMain,
  .menuAdminContainer,
  footer.pageFooterWrapper,
  .mainCallToAction,
  .header-search,
  footer.postFooterWrapper,
  aside.postAside,
  header.headerWrapper,
  a.buttonExit,
  a.menuMainAlt {
    display: none;
  }

  // Header Tweak
  .headerContent {
    max-width: 720px;
    margin: 0 auto;
    padding: 0;
  }

  // Edit ainLogo
  a.mainLogo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 185px;
    height: 90px;
    margin: 10px 0;
  }

  // Content layout
  .contentBlock .postContent {
    width: 100%;
    padding: 0;
    max-width: 720px;
    margin: 1rem auto 0;
    float: none;
    text-align: center;

    // Inner buttons
    a[class*="cta-button"] {
      display: block;
      width: 100%;
      position: relative;
      padding: 1rem 2rem;
      margin: 0 auto;
      @media (max-width: 768px) {
        margin: 0.5rem auto;
        padding: 0.75rem 1.5rem;
      }
    }
  }
}

body.subsite.our-shops .subsiteBody {
  h2 {
    color: $shop-orange;
  }
  p {
  position: relative;
  padding-top: 0;
  font-size: 29px;
  color: $shop-green;
  font-weight: 700;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  margin-bottom: 45px;
  }
}

body.subsite.our-shops .subsiteBody p:last-of-type {
  margin-bottom: 0;
}

// Hide header donate button on appeal/fundraiser/donateforms
.appealsPostBody,
body.donate {
  .menuAdminContainer .cta-button.donate {
    display: none;
  }
}

// IM-384
.PostCategory_centre-post-content {
  h1.title,
  .shareWrapper {
    text-align: center;
  }

  .shareList {
    justify-content: center;
  }
}

// IM-384
.logo-grid {
  font-size: 0 !important; // Get rid of non-breaking spaces
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    width: 25%;
    padding: 10px;

    @media (max-width: 600px) {
      width: 50%;
    }

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  img {
    width: 100% !important;
  }
}

// Hide totaliser on this appeal - IM-386
.fundraisers-wf-urgent-appeal .appealTotaliser {
  display: none;
}

// Donate form - Selected Amount
.donationForm .formQuestion.donationAmount:not(.ccAmount).donationSelected {
  color: $white;
}


.listedItem.listedFaq .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: 100%;
  height: 0;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.formQuestion.displayName.labelCheckBoxPair {
  display: none;
}


body:not(.PostCategory_appeal-enable-fundraiser-search) .findAFundraiserWidget {
  display: none;
}

// Subsite nav
.mainCallToAction .subsiteNav {
  display: none !important;
}

body.subsite.our-shops .mainCallToAction .subsiteNav {
  display: block !important;
}

// In mem - Search Format
.findAMemorial {
  #memorialNameSearch { border-bottom-right-radius: 0; }
  button.findAMemorialBtn { border-top-left-radius: 0; }
  button.findAMemorialBtn:before { color: $white; }
}

// In Mem - Form 
.formQuestion.inMemoryCreateAssociatedAppeal {
  display: none;
}


// Subsite default
body.subsite:not(.our-shops) {
  // Remove some stuff
  .subsiteNav, .headerTextSubsite, .GlobalAlert, .feedsTitle {
    display: none;
  }
  // Subsite homepage stuffs
  .subsiteFeedsWrapper {
    padding-top: #{$spacer * 0.5};
    padding-bottom: $spacer;
  }
  .subsiteBody {
    padding-top: #{$spacer * 2};
    margin-bottom: 0;
    p { font-size: 1.1em; }
  }

  // Card icon
  .feedItem:after,
  .listedPost:after { 
    background-image: url($assets-path + "arrow-pink.png");
  }

  .menuSub ul.topLevel > li:first-of-type a:before {
    content: "\f015";
    font-family: "FontAwesome";
    font-size: 1.45rem;
    margin-right: 5px;
    height: 20px;
    display: inline-flex;
    align-items: center;
  }
  @media (min-width: map-get($breakpoints, lg) + 1px) {
    .menuSub ul.topLevel > li:first-of-type a {
      font-size: 0;
      line-height: 1;
      height: 100%;
    }
  }

}



.inMemTotaliser {
  position: relative;
  padding-bottom: calc(60px + #{$spacer}) !important;
  display: flex;
  flex-wrap: wrap;

  .inMemContent, img {
    width: 100%;
    flex: unset;
  }
  
  .totaliserBG {
    bottom: 0;
    height: 300px;
    transform: rotate(90deg) translateX(-100%);
    transform-origin: left bottom;
  }

  .inMemIntro {
    font-size: $font-size-base;
    margin-top: $spacer;
  }
}

// Lazy load fix
.inMemoryListingPage .listedPost.listedInMemory:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) .banner {
  min-height: 0.1px;
}

// https://raisingit.atlassian.net/browse/DES-5711
@media (max-width: 568px) {
  #menuAdmin {
    display: flex;
    li:nth-child(n+6),
    .header-search,
    .donate {
      display: none;
    }
  }
}

// https://raisingit.atlassian.net/browse/DES-5839
.subsite.our-shops.homepage {
  .carouselSlideTitle:after {
    display: none;
  }
}