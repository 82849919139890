//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card;
  @include card-basic;

  &.homeBox1 {
    @include card (
      $card-details-background-colour: $_blue,
      $card-heading-colour: $_orange,
      $card-hover-details-background-colour: darken($_blue, 2.5%),
      $card-hover-heading-colour: $_orange

    );
    @include card-basic;
  }

  &.homeBox2 {
    @include card (
      $card-details-background-colour: $_dark-purple,
      $card-heading-colour: $_yellow,
      $card-hover-details-background-colour: darken($_dark-purple, 2.5%),
      $card-hover-heading-colour: $_yellow
    );
    @include card-basic;
  }

  &.homeBox3 {
    @include card (
      $card-details-background-colour: $_coral,
      $card-heading-colour: $white,
      $card-hover-details-background-colour: darken($_coral, 2.5%),
      $card-hover-heading-colour: $white
    );
    @include card-basic;
  }

  &.homeBox4 {
    @include card (
      $card-details-background-colour: $_yellow,
      $card-heading-colour: $_mid-purple,
      $card-hover-details-background-colour: darken($_yellow, 2.5%),
      $card-hover-heading-colour: $_mid-purple
    );
    @include card-basic;
  }

}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 3,
  $prefab: 2
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedsTitle { color: $_mid-purple; }
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $_yellow,
      $card-heading-colour: $_mid-purple,
      $card-hover-details-background-colour: darken($_yellow, 2.5%),
      $card-hover-heading-colour: $_mid-purple
    );
    @include card-basic;
    .feedItemsDetailsWrapper {
      border-bottom-right-radius: 20px;
    }
  }
}

.homeFeedBox2 .feedsTitle { color: $_yellow; }
.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $_mid-purple,
      $card-heading-colour: $_yellow,
      $card-hover-details-background-colour: darken($_mid-purple, 2.5%),
      $card-hover-heading-colour: $_yellow
    );
    @include card-basic;
  }
}

.homeFeedBox3 .feedsTitle {
  color: $_orange;
  z-index: 1;
}
.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $_dark-purple,
      $card-heading-colour:  $_yellow,
      $card-hover-details-background-colour: darken($_dark-purple, 2.5%),
      $card-hover-heading-colour:  $_yellow
    );
    @include card-basic;
  }
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}


.inMemoryListingPage  .listedPost.listedInMemory:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  overflow: hidden !important;
  margin: 0 #{$card-gap-width / 4} #{$card-gap-width / 2} !important;
  @include card (
    $card-details-background-colour: lighten($_teal, 5%),
    $card-heading-colour: darken($_pink, 2.5),
    $card-hover-details-background-colour: lighten($_teal, 2.5%),
    $card-hover-heading-colour: lighten($_pink, 2.5%),
  );
  &:after { background-image: url($assets-path + "arrow-pink.png"); }
}

// Subsite Default
body.subsite:not(.our-shops) {
  
  // Card layout
  @include home-feeds-prefab (
    $number-of-feeds: 2,
    $prefab: 1
  );

  // Card style
  .feedItem,
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
    @include card (
      $card-details-background-colour: $_teal,
      $card-heading-colour: $_pink,
      $card-hover-details-background-colour: lighten($_teal, 2.5%),
      $card-hover-heading-colour: $_pink
    );
    @include card-basic;
  }
}

// Shop subsite

body.subsite.our-shops {


  // Card style
  .feedItem,
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
    @include card (
      $card-heading-colour: $shop-orange,
      $card-hover-heading-colour: $shop-orange
    );
    @include card-basic;
  }
  
}